<template>
  <b-modal
    :id="modalId"
    :title="modalTitle"
    hide-footer
    centered
    scrollable
    title-class="font-18"
    ref="modalBoard"
    @hidden="handleCloseModal"
    @shown="setData"
  >
    <b-form
      class="purchase-form"
      @submit="handleSubmit"
    >
      <b-form-group
        label="Title"
        label-cols-md="2"
        label-align="right"
        label-for="title"
      >
        <b-form-input
          id="title"
          v-model="form.title"
          type="text"
          required
        />
      </b-form-group>
      <b-form-group
        label="Description"
        label-cols-md="2"
        label-align="right"
        label-for="description"
      >
        <b-form-textarea
          id="description"
          v-model="form.description"
          required
        />
      </b-form-group>
      <div class="d-flex justify-content-end mt-2">
        <b-button
          type="submit"
          variant="primary"
        >
          {{ buttonText }}
        </b-button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import {validateForm} from '@/utils/validation'

export default {
  props: {
    modalTitle: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: ''
    },
    modalId: {
      type: String,
      default: ''
    },
    modalData: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      form: {
        title: '',
        description: ''
      },
      images: [],
      sendImage: null
    }
  },
  methods: {
    setData () {
      if (this.modalData) {
        this.form.title = this.modalData.title
        this.form.description = this.modalData.description
        if (this.modalData.icon) {
          this.images.push({
            path: this.modalData.icon,
            default: 1,
            highlight: 1,
            caption: ''
          })
        }
      }
    },
    handleCloseModal () {
      this.form = {
        title: '',
        description: ''
      }
      this.images = []
      this.sendImage = null
    },
    async handleSubmit (event) {
      event.preventDefault()
      let isSuccess = true
      if (validateForm(this.form)) {
        const formData = new FormData()
        formData.append('title', this.form.title)
        formData.append('description', this.form.description)
        if (this.sendImage) {
          formData.append('icon', this.sendImage)
        }
        if (!this.modalData) {
          await this.$store.dispatch('platforms/createPlatform', formData)
            .then((response) => {
              if (response.code != 200) {
                isSuccess = false
              }
            })
        } else {
          formData.append('_method', 'PUT')
          await this.$store.dispatch('platforms/updatePlatform', {
            id: this.modalData.id,
            formData
          })
            .then((response) => {
              if (response.code != 200) {
                isSuccess = false
              }
            })
        }
        if (isSuccess) {
          this.$emit('success')
        }
      }
    },
    uploadImageSuccess(formData) {
      for (let value of formData.values()) {
        this.sendImage = value
      }
    },
    beforeRemove () {
      this.images = []
      this.sendImage = null
    },
    editImage (formData) {
      for (let value of formData.values()) {
        this.sendImage = value
      }
    }
  },
}
</script>

<style>
.image-container {
  width: 100% !important;
}
</style>