<template>
  <layout :loading="loading">
    <div class="d-flex align-items-center justify-content-between mb-4">
      <div class="d-flex align-items-center">
        <b-button
          variant="secondary"
          class="icon-button back-button"
          @click="goToProducts"
        >
          <i class="bx bx-arrow-back font-size-16" />
        </b-button>
        <h1 class="title-1">Product Platforms</h1>
      </div>
      <b-button
        class="mr-2"
        variant="success"
        @click="showModalForm"
      >
        <div class="d-flex align-items-center">
          <i class="bx bx-plus font-size-18 mr-2" />
          Add Platform
        </div>
      </b-button>
    </div>
    <b-tabs>
      <b-tab title="Categories" @click="goToCategories"></b-tab>
      <b-tab title="Platforms" active></b-tab>
    </b-tabs>
    <b-card bodyClass="pt-2">
      <div class="table-responsive mb-0">
        <table class="table table-hover">
          <thead>
            <tr>
              <th class="table__drag-col" />
              <th>#</th>
              <th>Title</th>
              <th>Description</th>
              <th class="table__actions">Actions</th>
            </tr>
          </thead>
          <draggable
            v-model="platforms"
            tag="tbody"
            handle=".drag-button"
            @end="handleSaveOrder"
          >
            <tr
              v-for="(platform, index) in platforms"
              :key="platform.id"
            >
              <td class="table__drag-col">
                <i class="fas fa-equals drag-button cursor-pointer" />
              </td>
              <td>{{ index + 1 }}</td>
              <td>{{ platform.title }}</td>
              <td>{{ platform.description }}</td>
              <td class="table__actions">
                <div class="d-flex align-items-center justify-content-end">
                  <i
                    class="bx bx-pencil text-warning cursor-pointer font-size-18"
                    @click="editHandler(platform)"
                  />
                  <i
                    class="bx bx-trash text-danger ml-2 cursor-pointer font-size-18"
                    @click="onDeleted(platform.id)"
                  />
                </div>
              </td>
            </tr>
          </draggable>
        </table>
      </div>
    </b-card>
    <FormModal
      :modalTitle="modalTitle"
      :buttonText="buttonText"
      :modalId="modalId"
      :modalData="modalData"
      @success="successHandler"
    />
  </layout>
</template>

<script>
import draggable from 'vuedraggable';
import { mapActions } from 'vuex'
import {convertObjectToFormData} from '@/utils/converters'
import FormModal from '@/components/products/platforms-modal.vue'

export default {
  components: {
    FormModal,
    draggable
  },
  data() {
    return {
      title: 'Platforms',
      modalId: null,
      modalTitle: 'Create new platform',
      buttonText: 'Create',
      modalData: null,
      loading: false
    }
  },
  computed: {
    platforms: {
      set(value) {
        this.$store.commit('platforms/SET_PLATFORMS', value);
      },
      get() {
        return this.$store.getters['platforms/getPlatforms'];
      }
    }
  },
  async mounted() {
    this.loading = true;
    await this.fetchPlatforms();
    this.loading = false;
  },
  methods: {
    ...mapActions({
      updateOrder: 'platforms/updatePlatformsOrder',
      fetchPlatforms: 'platforms/fetchPlatforms',
      deletePlatform: 'platforms/deletePlatform',
    }),
    async onDeleted (id) {
      await this.deletePlatform({
        id,
        formData: convertObjectToFormData({_method: 'DELETE'})
      })
      await this.fetchPlatforms();
    },
    showModalForm () {
      this.modalTitle = 'Create new platform'
      this.buttonText = 'Create'
      this.modalId = 'modal-platform-new'
      this.modalData = null
      setTimeout(() => {
        this.$bvModal.show(this.modalId)
      }, 10)
    },
    editHandler (item) {
      this.modalTitle = 'Edit platform'
      this.buttonText = 'Save'
      this.modalId = `modal-platform-${item.id}`
      this.modalData = item
      setTimeout(() => {
        this.$bvModal.show(this.modalId)
      }, 10)
    },
    successHandler () {
      this.fetchPlatforms();
      this.$bvModal.hide(this.modalId);
    },
    goToProducts() {
      this.$router.push({ name: 'manage-products' });
    },
    goToCategories() {
      this.$router.push({ name: 'product-categories' });
    },
    async handleSaveOrder() {
      this.loading = true;
      await this.updateOrder({
        order: this.platforms.reduce(
          (acc, item, index) => ({ ...acc, [item.id]: index + 1 }),
          {}
        )
      });
      this.loading = false;
    }
  },
}
</script>

<style lang="scss" scoped>
.table {
  th {
    border-top: none;
  }
  &__actions {
    width: 100px;
    text-align: right;
  }
  &__drag-col {
    width: 40px;
  }
}
</style>
